.project-card-view {
    box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
    color: white !important;
    background-color: transparent !important;
    /* opacity: 0.9 !important; */
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
  }
  .project-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px rgba(96, 81, 81, 0.561) !important;
  }
  .project-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
  }
    @media screen and (max-width: 600px) {
      .adjustPage {
        flex-direction: column;
      }
    }
    .lead1{
      text-align: justify;
    }